<template>
  <div class="shuffle-btn-positioning justify-center items-center gap-2">
    <transition name="fade">
      <!-- LANGUAGE SELECTOR DROPDOWN -->
      <div v-if="languageSelectorOpen" v-on-clickaway="() => closeLanguageSelector()"
      class="language-selector text-sm">
        <div class="language-selector-backdrop" />
        <!-- Dropdown Header -->
        <div class="py-1 px-2">
          Default Language
        </div>
        <!-- Search Input -->
        <div class="flex flex-row py-1.5 px-2 rounded-md items-center" style="background-color: #FFFFFF14;">
          <SearchIcon class="w-4 h-4 flex-shrink-0" />
          <input ref="languageSearch" v-model="searchQuery" type="text" placeholder="Search..."
          class="bg-transparent border-none outline-none focus:outline-none focus:ring-0 h-5 text-sm">
        </div>
        <!-- Languages List -->
        <div v-if="filteredLanguages.length > 0" class="scrollable-languages scrollbar-hide">
          <!-- "All Languages" Option -->
          <div class="gap-3 p-1 flex flex-row items-center rounded-md language-row"
          @click="removeAllLanguages">
            <div>
              <img v-if="selectedLanguages.length === 0" src="@/assets/icons/checked-checkbox.svg" class="w-6 h-6">
              <img v-if="selectedLanguages.length > 0" src="@/assets/icons/unchecked-checkbox.svg" class="w-6 h-6">
            </div>
            <div>
              All Languages
            </div>
          </div>
          <!-- All other languages -->
          <div v-for="(language, index) in filteredLanguages" :key="index"
          class="gap-3 p-1 flex flex-row items-center rounded-md language-row"
          @click="toggleIfLanguageSelected(language)">
            <!-- Checkbox -->
            <div>
              <img v-if="selectedLanguages.some(selectedLanguage => selectedLanguage.name === language.name)"
              src="@/assets/icons/checked-checkbox.svg" class="w-6 h-6">
              <img v-else src="@/assets/icons/unchecked-checkbox.svg" class="w-6 h-6">
            </div>
            <!-- Name -->
            <div>
              {{ language.name }}
            </div>
          </div>
          <div />
        </div>
        <!-- No Search Results state -->
        <div v-else class="flex p-1 justify-center">
          No matching languages
        </div>
      </div>
    </transition>
    <!-- LANGUAGE DROPDOWN OPENER BUTTON -->
    <button class="group gap-1 p-2 flex flex-row items-center rounded-lg relative" tabindex="-1"
    @click="toggleLanguageSelector" @mouseenter="languageSelectorHovered = true" @mouseleave="languageSelectorHovered = false">
      <transition name="fade">
        <div v-if="languageSelectorOpen || languageSelectorHovered" class="button-backdrop" />
      </transition>
      <div class="transition-opacity" :class="languageSelectorOpen ? 'opacity-100' : 'opacity-80 group-hover:opacity-100'">
        <img src="@/assets/icons/globe.svg" class="">
      </div>
      <!-- <div class="language-display"> -->
      <BaseText v-if="selectedLanguages.length === 0" type="label" size="sm" class="pl-1 transition-opacity language-display" 
      :class="languageSelectorOpen ? 'opacity-100' : 'opacity-80 group-hover:opacity-100'">
        All Languages
      </BaseText>
      <BaseText v-if="selectedLanguages.length === 1" type="label" size="sm" class="pl-1 transition-opacity language-display" 
      :class="languageSelectorOpen ? 'opacity-100' : 'opacity-80 group-hover:opacity-100'">
        {{ selectedLanguages[0].name }}
      </BaseText>
      <BaseText v-if="selectedLanguages.length > 1" type="label" size="sm" class="pl-1 transition-opacity language-display" 
      :class="languageSelectorOpen ? 'opacity-100' : 'opacity-80 group-hover:opacity-100'">
        {{ selectedLanguages[0].name }} +{{ selectedLanguages.length - 1 }}
      </BaseText>
      <!-- </div> -->
      <div class="transform transition-transform duration-200"
      :style="languageSelectorOpen ? 'transform: scale(1, -1)' : ''">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"
        class="transition-opacity" :class="languageSelectorOpen ? 'opacity-100' : 'opacity-80 group-hover:opacity-100'">
          <path d="M13.25 8.75L10 12.25L6.75 8.75" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
    </button>
    <img src="@/assets/icons/line.svg" class="opacity-20">
    <!-- SHUFFLE BUTTON -->
    <button class="shuffle-button group relative rounded-md gap-1 flex flex-row items-center px-0.5" tabindex="-1"
    :class="{
      'shuffling-state': isShuffling && canBeShuffled,
      'inner-buttons': !isShuffling && canBeShuffled,
      'disabled-inner-buttons': !canBeShuffled
    }"
    @mouseenter="handleMouseOnShuffleButton"
    @mouseleave="handleMouseOffShuffleButton"
    @click="shuffle">
      <!-- Shuffling animated conic gradient background -->
      <transition name="fade">
        <div v-if="isShuffling" class="absolute top-0 bottom-0 left-0 right-0 rounded-md overflow-hidden" style="z-index: -5">
          <div class="animated-conic-container">
            <div class="conic-top-wrapper">
              <div class="conic-top" />
            </div>
            <div class="conic-bottom-wrapper">
              <div class="conic-bottom" />
            </div>
          </div>
        </div>
      </transition>
      <!-- Shuffle hover state (when shuffle is available) -->
      <transition name="fade">
        <div v-if="!isShuffling && canBeShuffled && shuffleHovered" class="button-backdrop" />
      </transition>
      <div ref="shuffleAnimationContainer" class="flex flex-row items-center">
        <ShuffleHoveredLottie
          v-show="!isShuffling"
          ref="hoverAnimation"
          :autoplay="shuffleHovered && canBeShuffled"
          :is-hovered="shuffleHovered && canBeShuffled"
          :style="{ opacity: canBeShuffled ? 1 : 0.3 }"
        />
        <ShuffleButtonLottie v-if="isShuffling" ref="shuffleAnimation"/>
        <BaseText v-if="isShuffling" type="label" size="sm" class="fade-in pl-0.5 py-2 pr-2.5">
          Shuffling...
        </BaseText>
        <BaseText v-else type="label" size="sm" :class="{'group-hover:opacity-100': canBeShuffled}"
        class="fade-in opacity-80 transition-opacity pl-0.5 py-2 pr-2.5">
          Shuffle
        </BaseText>
      </div>
      <!-- Info tooltip (When user can't shuffle) -->
      <transition name="fade">
        <div v-show="!canBeShuffled && disabledToolTipVisible" class="absolute bottom-full -left-2 pb-1.5"
        style="z-index: 100000">
          <div class="disabled-tooltip px-4 py-3.5">
            <div class="flex flex-row gap-1.5 whitespace-nowrap text-sm items-center">
              <div>Make a</div>
              <div
                class="flex flex-row gap-1 py-1 pl-1.5 pr-2 rounded-xl items-center cursor-pointer text-xs"
                style="background-color: #FFFFFF14;"
                @click="$emit('open-keyword-search')"
              >
                <SearchIcon
                  class="w-4 h-4"
                />
                <div>Search</div>
              </div>
              <div>or</div>
            </div>
            <div class="flex flex-row gap-1.5 whitespace-nowrap text-sm items-center">
              <div class="flex flex-row gap-1 py-1 pr-5 pl-2 rounded-xl items-center cursor-pointer text-xs"
              style="background-color: #FFFFFF14;" @click="triggerFilterSearchClick">
                <img src="@/assets/icons/apply-filter-icon.svg" class="w-3 h-3 opacity-40">
                <div>Apply a filter</div>
              </div>
              <div>to enable.</div>
            </div>
          </div>
        </div>
      </transition>
    </button>
    <div class="opener-buttons-backdrop" />
  </div>
</template>

<script>

import { mixin as clickaway } from 'vue-clickaway2'
import smoothReflow from 'vue-smooth-reflow'
import EventBus from '../../plugins/BaseNotifications/EventBus'
import SearchIcon from '../../../src/components/globals/Icons/SearchIcon.vue'
import firebase from '../../api/config/FirebaseConfig'
import ChevronIcon from '../../components/globals/Icons/ChevronIcon.vue'
import { mapGetters } from 'vuex'

// Icons
import ShuffleHoveredLottie from '../../components/globals/Icons/DiscoveryIcons/ShuffleHoveredLottie.vue'
import ShuffleButtonLottie from '../../components/globals/Icons/DiscoveryIcons/ShuffleButtonLottie.vue'

export default {
  name: 'ShuffleButton',
  components: {
    SearchIcon,
    ChevronIcon,
    ShuffleHoveredLottie,
    ShuffleButtonLottie
  },
  mixins: [clickaway, smoothReflow],
  props: {
    canBeShuffled: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      hoverAnimation: null,
      shuffleAnimation: null,
      isShuffling: false,
      languageSelectorOpen: false,
      languageSelectorHovered: false,
      shuffleHovered: false,
      disabledToolTipVisible: false,
      disabledToolTipTimeout: null,
      languages: [],
      selectedLanguages: [],
      searchQuery: ''
    }
  },
  computed: {
    ...mapGetters('AuthModule', ['getUser']),
    filteredLanguages () {
      const filtered = this.languages.filter(language =>
        language.name.toLowerCase().includes(this.searchQuery.toLowerCase())
      )

      return filtered.sort((a, b) => {
        const aSelected = this.selectedLanguages.some(selectedLanguage => selectedLanguage.name === a.name)
        const bSelected = this.selectedLanguages.some(selectedLanguage => selectedLanguage.name === b.name)

        if (aSelected && !bSelected) {
          return -1
        } else if (!aSelected && bSelected) {
          return 1
        } else {
          return 0
        }
      })
    }
  },
  watch: {
  },
  mounted () {
    this.getLanguages()
    this.selectedLanguages = this.getUser.defaultLanguages || []
    this.$emit('update:selectedLanguages', this.selectedLanguages)

    EventBus.$on('shuffling', (alert) => this.enqueueAlert(alert))
    this.$smoothReflow({
      el: '.shuffle-button',
      property: ['width'],
      transition: 'width 0.2s ease-in-out'
    })

    EventBus.$on('language-change', (alert) => this.enqueueAlert(alert))
    this.$smoothReflow({
      el: '.language-display',
      property: ['width'],
      transition: 'width 0.2s ease-in-out'
    })
  },
  methods: {
    async removeAllLanguages () {
      if (this.selectedLanguages.length === 0) {
        return
      }
      const db = firebase.firestore()
      const user = firebase.auth().currentUser
      const userDocRef = db.collection('auth-users').doc(user.uid)

      try {
        const userDoc = await userDocRef.get()
        const currentLanguages = userDoc.data().defaultLanguages || []

        if (currentLanguages.length > 0) {
          await userDocRef.update({
            defaultLanguages: firebase.firestore.FieldValue.arrayRemove(...currentLanguages)
          })
        }

        this.selectedLanguages = []
        this.$emit('update:selectedLanguages', this.selectedLanguages)
        this.$store.commit('AuthModule/SET_REMOVE_DEFAULT_LANGUAGES')
      } catch (error) {
        console.log('error in removing languages', error)
      }
    },
    triggerFilterSearchClick () {
      const targetDiv = document.getElementById('filterSearch')
      if (targetDiv) {
        targetDiv.click()
      }
    },
    closeLanguageSelector () {
      this.languageSelectorOpen = false
    },
    toggleLanguageSelector () {
      this.languageSelectorOpen = !this.languageSelectorOpen
      this.$nextTick(() => {
        if (this.languageSelectorOpen) {
          this.$refs.languageSearch.focus()
        }
      })
    },
    async toggleIfLanguageSelected (language) {
      const db = firebase.firestore()
      const user = firebase.auth().currentUser

      const index = this.selectedLanguages.findIndex((selectedLanguage) => selectedLanguage.name === language.name)
      const userDocRef = db.collection('auth-users').doc(user.uid)

      try {
        const userDoc = await userDocRef.get()

        if (index === -1) {
          this.selectedLanguages.push(language)

          if (userDoc.exists) {
            const userData = userDoc.data()
            if (!userData.defaultLanguages) {
              await userDocRef.set({
                defaultLanguages: this.selectedLanguages
              }, { merge: true })
            } else {
              await userDocRef.update({
                defaultLanguages: firebase.firestore.FieldValue.arrayUnion(language)
              })
            }
          } else {
            await userDocRef.set({
              defaultLanguages: this.selectedLanguages
            })
          }
        } else {
          this.selectedLanguages.splice(index, 1)
          if (userDoc.exists) {
            await userDocRef.update({
              defaultLanguages: firebase.firestore.FieldValue.arrayRemove(language)
            })
          }
        }

        this.$emit('update:selectedLanguages', this.selectedLanguages)
      } catch (error) {
        console.log('error in updating languages', error)
      }
    },
    shuffle () {
      if (!this.canBeShuffled) {
        return
      }
      // this.playShuffleAnimation()
      this.isShuffling = true
      this.$emit('shuffle')
      setTimeout(() => {
        this.isShuffling = false
      }, 3000)
    },
    // Hover states for shuffle & disabled tooltip when shuffle is disabled
    handleMouseOnShuffleButton () {
      if (this.canBeShuffled && !this.isShuffling) {
        this.shuffleHovered = true
      } else {
        this.disabledToolTipTimeout = setTimeout(() => {
          this.disabledToolTipVisible = true
        }, 1000)
      }
    },
    // Mouse leave handling
    handleMouseOffShuffleButton () {
      this.shuffleHovered = false
      this.disabledToolTipVisible = false
      if (this.disabledToolTipTimeout) {
        clearTimeout(this.disabledToolTipTimeout)
      }
    },

    async getLanguages () {
      const db = firebase.firestore()

      const snapshot = await db.collection('languages').get()

      const newDocs = snapshot.docs?.map((doc) => {
        const docData = {
          ...(doc.data() || [])
        }

        return { name: docData.language }
      })

      const sortedDocs = newDocs.sort((a, b) => (a.name > b.name ? 1 : -1))
      this.languages = sortedDocs
    }
  }
}
</script>

<style scoped>
.language-display {
  flex-wrap: nowrap;
  white-space: nowrap;
  /* overflow: hidden;  */
}
.shuffle-btn-positioning {
    display: flex;
    flex-direction: row;
    position: fixed;
    left: 293px;
    bottom: 20.5px;
    height: 44px;
    z-index: 1000;
    padding: 4px;
    color: white;
    border-radius: 10px;
}
.scrollable-languages {
    display: flex;
    flex-direction: column;
    gap: 4px;
    overflow-y: scroll;
}
.language-selector {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: calc(100% + 2px);
    left: 0;
    width: 252px;
    height: 260px;
    color: white;
    padding-left: 8px;
    padding-top: 8px;
    padding-right: 8px;
    gap: 4px;
    border-radius: 12px;
    z-index: 1000;
    overflow: hidden;
}
.language-selector-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 4px;
  z-index: -10;
  border-radius: 12px;
  background-color: rgba(6, 7, 16, 0.92);
  backdrop-filter: blur(16px);
}
.button-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 6px;
  z-index: 10;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0.06) 100%);
}
.opener-buttons-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -10;
  background-color: rgba(6, 7, 16, 0.92);
  backdrop-filter: blur(16px);
  border-radius: 10px;
}

.animated-conic-container {
  width: 100%;
  height: 100%;
  z-index: -1;
  border-radius: 6px;
  background-clip: padding-box !important;
  border: 1.5px solid transparent;
  background: linear-gradient(180deg, rgba(109, 49, 232, 0.06) 0%, rgba(109, 49, 232, 0.08) 100%), rgba(109, 49, 232, 0.1);
  box-shadow: inset 0px 0px 14px 0px rgba(109, 49, 232, 0.5);
  background-color: black;
  overflow: hidden;
}
.conic-top-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 50%;
  overflow: hidden;
  z-index: -2;
}
@property --conic-angle-top {
  syntax: "<angle>";
  initial-value: -90deg;
  inherits: false;
}
.conic-top {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 200%;
  background: conic-gradient(
    from var(--conic-angle-top) in oklch,
    oklch(50.38% 0.235 290.02), 
    oklch(73.65% 0.124 297.72) 50%,
    oklch(50.38% 0.235 290.02) 50%,
    oklch(73.65% 0.124 297.72)
  );
  animation: spin-conic-top 1s cubic-bezier(.81,.36,.17,.63) infinite;
}
@keyframes spin-conic-top {
  to { --conic-angle-top: 90deg; }
}

.conic-bottom-wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50%;
  overflow: hidden;
  z-index: -2;
}
@property --conic-angle-bottom {
  syntax: "<angle>";
  initial-value: 90deg;
  inherits: false;
}
.conic-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 200%;
  background: conic-gradient(
    from var(--conic-angle-bottom) in oklch,
    oklch(50.38% 0.235 290.02), 
    oklch(73.65% 0.124 297.72) 50%,
    oklch(50.38% 0.235 290.02) 50%,
    oklch(73.65% 0.124 297.72)
  );
  animation: spin-conic-bottom 1s cubic-bezier(.81,.36,.17,.63) infinite;
}
@keyframes spin-conic-bottom {
  to { --conic-angle-bottom: 270deg; }
}

.language-row:hover {
  cursor: pointer;
  background-color: #FFFFFF14;
}
.disabled-tooltip {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    box-sizing: padding-box;
    background-clip: padding-box !important;
    background-color: #06070FEB;
    color: white;
    border-radius: 12px;
    backdrop-filter: blur(10px);
}
.fade-enter-active, .fade-leave-active {
    transition: opacity 150ms ease-in-out;
}
.fade-enter, .fade-enter-from, .fade-leave-to {
  opacity: 0;
}
.fade-enter-to, .fade-leave-from {
  opacity: 1;
}
.disabled-inner-buttons {
    cursor: default;
    color: #FFFFFF4D;
}

.fade-in {
  animation: fadeIn 1s ease-in-out;
}
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}
</style>